import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-iframe-external',
  templateUrl: './iframe-external.component.html',
  styleUrls: ['./iframe-external.component.scss']
})
export class IframeExternalComponent implements OnInit {

  @Input() iframeTitle: string = ''
  @Input() iframeSrc: any = ''
  @Input() isMobile: any = false

  constructor(private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.iframeSrc =  this.domSanitizer.bypassSecurityTrustResourceUrl(this.iframeSrc)
  }

}
