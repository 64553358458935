import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';

interface SlidesList {
  videoCategory: string,
  headerText: string,
  type: string,
  dataList: any
}
@Component({
  selector: 'app-infocusslide',
  templateUrl: './infocusslide.component.html',
  styleUrls: ['./infocusslide.component.scss']
})
export class InfocusslideComponent implements OnInit {
  @Input() slidesList: SlidesList[] = [];
  currentTab: string = 'course_intro';
  data: SlidesList;

  apiKey = 'AIzaSyB7neTUnErCuxU3bQ6ttZYmLDwQQlAnWL0'; //NOSONAR

  listId = 'PLMzVnzbHaB8uKAmzOPKVuiC7gVzBKKvNE'; 
  channelId = 'PLMzVnzbHaB8tpuYUiTTITmSgMfH8uZWMN';
  pmTalksListId = 'PLMzVnzbHaB8uIwll5pY7z7W7RI88h7nIv'
  courseListId = 'PLMzVnzbHaB8uqc3APlK_oXkDaOhmMmrrY'

  landingPageurl = 'apis/public/v8/playlist/landingpage'
  showVideoGallery = true;
  pmTalksArr = [
    {
      "kind": "youtube#playlistItem",
      "etag": "pRRjqQesKEXlMS2sSqypC5dePVU",
      "id": "UExNelZuemJIYUI4dUl3bGw1cFk3ejdXN1JJODhoN25Jdi41NkI0NEY2RDEwNTU3Q0M2",
      "snippet": {
        "publishedAt": "2024-02-09T05:52:38Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "Hon'ble PM Narendra Modi's address at the #RozgarMela",
        "description": "At the #RozgarMela, Hon'ble PM @narendramodi emphasized the importance of continuous learning and encouraged all newly inducted recruits to engage with the #iGOTKarmayogi portal to contribute towards shaping a #ViksitBharat.\n\n#JanRozgar #NayaBharat #Recruitment #SabkaSaathSabkaVikas",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/PPTRifLJtik/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/PPTRifLJtik/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/PPTRifLJtik/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/PPTRifLJtik/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/PPTRifLJtik/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8uIwll5pY7z7W7RI88h7nIv",
        "position": 0,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "PPTRifLJtik"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "PPTRifLJtik",
        "videoPublishedAt": "2023-09-26T10:46:12Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "kBwqi_bwTKQGy70-UbA9GdWvDfo",
      "id": "UExNelZuemJIYUI4dUl3bGw1cFk3ejdXN1JJODhoN25Jdi4yODlGNEE0NkRGMEEzMEQy",
      "snippet": {
        "publishedAt": "2024-02-09T05:53:09Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "Hon'ble PM Narendra Modi's address at the 11th Rozgar Mela",
        "description": "At the 11th #RozgarMela on 30th November 2023, the Hon PM Narendra Modi emphasized the significance of continuous learning. \n\nHe also highlighted the positive impact of #KarmayogiPrarambh, and urged newly appointed govt officials to actively participate at the #iGOTKarmayogi platform, and thus contribute towards a #ViksitBharat.",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/4Kg3_zkYiz0/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/4Kg3_zkYiz0/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/4Kg3_zkYiz0/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/4Kg3_zkYiz0/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/4Kg3_zkYiz0/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8uIwll5pY7z7W7RI88h7nIv",
        "position": 1,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "4Kg3_zkYiz0"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "4Kg3_zkYiz0",
        "videoPublishedAt": "2023-11-30T12:31:21Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "FimKFVF3ld4NbkBIgPjAuGmG_wE",
      "id": "UExNelZuemJIYUI4dUl3bGw1cFk3ejdXN1JJODhoN25Jdi4wMTcyMDhGQUE4NTIzM0Y5",
      "snippet": {
        "publishedAt": "2024-02-09T05:53:42Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "PM Shri Narendra Modi's address at the Rozgar Mela",
        "description": "At the Rozgar Mela, PM Shri Narendra Modi emphasized the importance of continuous learning. He highlighted the array of 600+ courses accessible on the iGOT Karmayogi platform and encouraged all recent recruits to engage with the portal, leveraging its offerings to prepare themselves for the future and contribute to a New India.",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/Nf0nDwLricI/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/Nf0nDwLricI/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/Nf0nDwLricI/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/Nf0nDwLricI/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/Nf0nDwLricI/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8uIwll5pY7z7W7RI88h7nIv",
        "position": 2,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "Nf0nDwLricI"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "Nf0nDwLricI",
        "videoPublishedAt": "2023-08-29T06:07:27Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "MMooFhZbYZmXuteBchAe7-PxOuw",
      "id": "UExNelZuemJIYUI4dUl3bGw1cFk3ejdXN1JJODhoN25Jdi41MjE1MkI0OTQ2QzJGNzNG",
      "snippet": {
        "publishedAt": "2024-02-09T05:54:03Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "Highlights of Hon'ble Prime Minister's Address on #RozgarMela (April, 2023) | #MissionKarmayogi",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/jmim6fgVe9Y/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/jmim6fgVe9Y/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/jmim6fgVe9Y/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/jmim6fgVe9Y/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/jmim6fgVe9Y/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8uIwll5pY7z7W7RI88h7nIv",
        "position": 3,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "jmim6fgVe9Y"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "jmim6fgVe9Y",
        "videoPublishedAt": "2023-04-24T08:47:42Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "4x07Jd_jAEfsIxkpZBirkhY4MJY",
      "id": "UExNelZuemJIYUI4dUl3bGw1cFk3ejdXN1JJODhoN25Jdi4wOTA3OTZBNzVEMTUzOTMy",
      "snippet": {
        "publishedAt": "2024-02-09T05:54:47Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "Highlights from Hon'ble Prime Minister's Address on #CivilServicesDay, 2023 | #MissionKarmayogi",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/64X1qkTK8VI/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/64X1qkTK8VI/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/64X1qkTK8VI/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/64X1qkTK8VI/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/64X1qkTK8VI/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8uIwll5pY7z7W7RI88h7nIv",
        "position": 4,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "64X1qkTK8VI"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "64X1qkTK8VI",
        "videoPublishedAt": "2023-04-24T08:41:55Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "wHHHz2BfTx3WP3qMex9A5eYNQGE",
      "id": "UExNelZuemJIYUI4dUl3bGw1cFk3ejdXN1JJODhoN25Jdi4xMkVGQjNCMUM1N0RFNEUx",
      "snippet": {
        "publishedAt": "2024-02-09T05:55:44Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "Highlights of PM Modi's interaction with #Karmayogis | #RozgarMela, January 2023 | Mission Karmayogi",
        "description": "",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/fvJgVTP7AKc/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/fvJgVTP7AKc/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/fvJgVTP7AKc/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/fvJgVTP7AKc/sddefault.jpg",
            "width": 640,
            "height": 480
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8uIwll5pY7z7W7RI88h7nIv",
        "position": 5,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "fvJgVTP7AKc"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "fvJgVTP7AKc",
        "videoPublishedAt": "2023-02-11T19:18:18Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "KnKBtLd92LnTVcDLxVBSVsEW2Yo",
      "id": "UExNelZuemJIYUI4dUl3bGw1cFk3ejdXN1JJODhoN25Jdi41MzJCQjBCNDIyRkJDN0VD",
      "snippet": {
        "publishedAt": "2024-02-09T05:57:01Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "Karmyogi Prarambh",
        "description": "Karmayogi Prarambh is an e-learning module specially designed for new entrants in government to enhance their skills and attitudes through courses on iGOT Karmayogi. This is to enable their transition from Karamcharis to Karmayogis and contribute to public service delivery for the nation. \n\nCourses included in the module: \nCode of Conduct for Government Employees\nPrevention of Sexual Harassment of Women at Workplace\nUnderstanding Motivation\nSelf Leadership\nStress Management\nEffective Communication \nMicrosoft Word Beginners\nMicrosoft Excel for Beginners\n\nLogin to igotkarmayogi.gov.in to explore more!",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/fbwJv7bIBXQ/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/fbwJv7bIBXQ/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/fbwJv7bIBXQ/hqdefault.jpg",
            "width": 480,
            "height": 360
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8uIwll5pY7z7W7RI88h7nIv",
        "position": 6,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "fbwJv7bIBXQ"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "fbwJv7bIBXQ",
        "videoPublishedAt": "2022-11-22T05:42:32Z"
      }
    }
  ];

  courseListArr = [
    {
      "kind": "youtube#playlistItem",
      "etag": "xDqhlmbD62m6CLqeoH1uP655f8Q",
      "id": "UExNelZuemJIYUI4dXFjM0FQbEtfb1hrRGFPaG1NbXJyWS41NkI0NEY2RDEwNTU3Q0M2",
      "snippet": {
        "publishedAt": "2024-02-29T09:30:23Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "Prof. Ashwini Chhatre | Insights from Data for Policy",
        "description": "The Indian School of Business is offering a #course called 'Insights from Data for Policy'. It's designed to help you understand the importance of data analysis and teach you some commonly used concepts.\nWorth checking out!",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/8Y2VUsFLytQ/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/8Y2VUsFLytQ/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/8Y2VUsFLytQ/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/8Y2VUsFLytQ/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/8Y2VUsFLytQ/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8uqc3APlK_oXkDaOhmMmrrY",
        "position": 0,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "8Y2VUsFLytQ"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "8Y2VUsFLytQ",
        "videoPublishedAt": "2024-02-13T07:25:22Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "k7haHweWkY1DozbjEw7cFSNSc_g",
      "id": "UExNelZuemJIYUI4dXFjM0FQbEtfb1hrRGFPaG1NbXJyWS4yODlGNEE0NkRGMEEzMEQy",
      "snippet": {
        "publishedAt": "2024-02-29T09:38:02Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "Dr Preet Deep Singh | Invest India",
        "description": "#iGOT Karmayogi presents two courses on Artificial Intelligence for govt officers, by Dr Preet Deep Singh, Vice President & Chief Analytics Officer at Invest India. The courses are a masterclass - highly recommended!",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/x67MHC1Y1eg/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/x67MHC1Y1eg/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/x67MHC1Y1eg/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/x67MHC1Y1eg/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/x67MHC1Y1eg/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8uqc3APlK_oXkDaOhmMmrrY",
        "position": 1,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "x67MHC1Y1eg"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "x67MHC1Y1eg",
        "videoPublishedAt": "2024-02-07T06:14:18Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "Efg8Jl8SxuMywrPF5NNQxbJvpT4",
      "id": "UExNelZuemJIYUI4dXFjM0FQbEtfb1hrRGFPaG1NbXJyWS4wMTcyMDhGQUE4NTIzM0Y5",
      "snippet": {
        "publishedAt": "2024-02-29T09:38:56Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "Birendra Kumar | 'Uniform Specification of Food grains' course",
        "description": "Presenting the ‘Uniform Specification of Food grains' course by the Food Corporation of India, tailored for #Karmayogis engaged with procurement of food grains.",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/ZEpjsJI5IjM/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/ZEpjsJI5IjM/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/ZEpjsJI5IjM/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/ZEpjsJI5IjM/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/ZEpjsJI5IjM/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8uqc3APlK_oXkDaOhmMmrrY",
        "position": 2,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "ZEpjsJI5IjM"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "ZEpjsJI5IjM",
        "videoPublishedAt": "2024-02-07T06:08:39Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "RKfwKPm4NoOnhDCvk6pFk1naI_I",
      "id": "UExNelZuemJIYUI4dXFjM0FQbEtfb1hrRGFPaG1NbXJyWS41MjE1MkI0OTQ2QzJGNzNG",
      "snippet": {
        "publishedAt": "2024-02-29T09:39:19Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "Nimisha Singh | Quality Council of India",
        "description": "#iGOTKarmayogi presents  'Fundamentals of Program and Project Management' and 'Introduction: Basics of Project Management' courses on Project Management by the Quality Council of India, to master skills like finance and budgeting. Be a #Karmayogi in the journey towards #ViksitBharat!",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/Qyyacy-C2-g/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/Qyyacy-C2-g/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/Qyyacy-C2-g/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/Qyyacy-C2-g/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/Qyyacy-C2-g/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8uqc3APlK_oXkDaOhmMmrrY",
        "position": 3,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "Qyyacy-C2-g"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "Qyyacy-C2-g",
        "videoPublishedAt": "2024-02-07T06:02:43Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "JSMuV_3SlWqN64T-pYFLKhAYE8s",
      "id": "UExNelZuemJIYUI4dXFjM0FQbEtfb1hrRGFPaG1NbXJyWS4wOTA3OTZBNzVEMTUzOTMy",
      "snippet": {
        "publishedAt": "2024-02-29T09:39:40Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "Gaurav Shah | 'Exploring Development: The Idea and Its Aspects' course",
        "description": "The Indian School of Development Management course 'Exploring Development: The Idea and Its Aspects' at #iGOTKarmayogi aims to equip government officials with diverse perspectives on development.",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/pZT2YvTmKXw/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/pZT2YvTmKXw/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/pZT2YvTmKXw/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/pZT2YvTmKXw/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/pZT2YvTmKXw/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8uqc3APlK_oXkDaOhmMmrrY",
        "position": 4,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "pZT2YvTmKXw"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "pZT2YvTmKXw",
        "videoPublishedAt": "2024-02-07T05:52:03Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "ItKLZKCRQM4OinK_ZpjfN_kXMs4",
      "id": "UExNelZuemJIYUI4dXFjM0FQbEtfb1hrRGFPaG1NbXJyWS4xMkVGQjNCMUM1N0RFNEUx",
      "snippet": {
        "publishedAt": "2024-02-29T09:40:00Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "Anshuman Kamila, IES | 'Public Policy Writing' course",
        "description": "Anshuman Kamila, IES, highlights the importance of the 'Public Policy Writing' course on #iGOTKarmayogi.\nHe highly recommends the course for govt officials, to equip them with essential skills in effective document writing.",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/Ln_AbuR7y_A/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/Ln_AbuR7y_A/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/Ln_AbuR7y_A/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/Ln_AbuR7y_A/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/Ln_AbuR7y_A/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8uqc3APlK_oXkDaOhmMmrrY",
        "position": 5,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "Ln_AbuR7y_A"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "Ln_AbuR7y_A",
        "videoPublishedAt": "2024-02-07T05:43:40Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "LKu6yqUB3loJm3WdQ-IAX4iAT0w",
      "id": "UExNelZuemJIYUI4dXFjM0FQbEtfb1hrRGFPaG1NbXJyWS41MzJCQjBCNDIyRkJDN0VD",
      "snippet": {
        "publishedAt": "2024-02-29T09:40:18Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "Prof OP Agarwal, IAS (Retd.) | ‘Public Policy Writing’ course",
        "description": "Prof OP Agarwal, IAS (Retd.) is a respected name in public policy with a wealth of experience across decades. His 'Public Policy Writing' course by the Indian School of Public Policy (ISPP) on #iGOTKarmayogi is a policy masterclass govt officials cannot miss. Strongly recommended!",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/KvaA9Z8WN38/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/KvaA9Z8WN38/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/KvaA9Z8WN38/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/KvaA9Z8WN38/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/KvaA9Z8WN38/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8uqc3APlK_oXkDaOhmMmrrY",
        "position": 6,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "KvaA9Z8WN38"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "KvaA9Z8WN38",
        "videoPublishedAt": "2024-02-07T05:34:23Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "NiJxA9ItrB9wzigiqzS6kn0BtVk",
      "id": "UExNelZuemJIYUI4dXFjM0FQbEtfb1hrRGFPaG1NbXJyWS5DQUNERDQ2NkIzRUQxNTY1",
      "snippet": {
        "publishedAt": "2024-03-01T09:05:19Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "Dr. Neeraj Tiwari | 3 Criminal Laws",
        "description": "#iGOTKarmayogi presents an introduction to the Three New Criminal Laws: the Bharatiya Nyaya Sanhita, 2023, the Bharatiya Nagarik Suraksha Sanhita, 2023 and the Bharatiya Sakshya Adhiniyam, 2023. \n\nThe three landmark Laws, in step with the vision of Viksit Bharat, replace three colonial-era laws: the Indian Penal Code, 1860, the Code of Criminal Procedure, 1973 and the Indian Evidence Act, 1872.\n\nThe new Laws unshackle the Indian criminal justice system from its colonial legacy and mark a monumental, revolutionary change from Dand Vidhan to Nyaya Vidhan.",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/ANQr0UJLsS0/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/ANQr0UJLsS0/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/ANQr0UJLsS0/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/ANQr0UJLsS0/sddefault.jpg",
            "width": 640,
            "height": 480
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8uqc3APlK_oXkDaOhmMmrrY",
        "position": 7,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "ANQr0UJLsS0"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "ANQr0UJLsS0",
        "videoPublishedAt": "2024-03-01T09:05:25Z"
      }
    }
  ]

  channelListArr = [
    {
      "kind": "youtube#playlistItem",
      "etag": "1uikzxBMKgpcBmqTnqLvMejNH0M",
      "id": "UExNelZuemJIYUI4dHB1WVVpVFRJVG1TZ01mSDh1WldNTi45NzUwQkI1M0UxNThBMkU0",
      "snippet": {
        "publishedAt": "2024-02-15T10:18:36Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "Green Revolution to Amrit Kaal | Dr. Ramesh Chand",
        "description": "Learn everything you need to know about the transformations in Indian Agriculture in this episode of Karmayogi Talks, with Sh Ramesh Chand, Member-NITI Aayog.",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/YCEHvTnQDFA/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/YCEHvTnQDFA/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/YCEHvTnQDFA/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/YCEHvTnQDFA/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/YCEHvTnQDFA/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8tpuYUiTTITmSgMfH8uZWMN",
        "position": 0,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "YCEHvTnQDFA"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "YCEHvTnQDFA",
        "videoPublishedAt": "2024-02-15T10:22:12Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "EZ5h5hv4dzkpGFTbi8xXtEOMeUU",
      "id": "UExNelZuemJIYUI4dHB1WVVpVFRJVG1TZ01mSDh1WldNTi5ENDU4Q0M4RDExNzM1Mjcy",
      "snippet": {
        "publishedAt": "2024-02-08T06:56:07Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "The Story of Lithium Ion Batteries | Dr. Rachid Yazami",
        "description": "This episode of Karmayogi Talks features Dr Rachid Yazami, known for his role in developing the graphite anode in lithium-ion batteries, in conversation with Dr Rahul Walawalkar, founder of India Energy Storage Alliance. \n\nThe discussion centered around the latest developments in lithium-ion batteries, addressing current challenges and exploring future innovations in energy storage.",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/V_dtC8p6Cqs/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/V_dtC8p6Cqs/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/V_dtC8p6Cqs/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/V_dtC8p6Cqs/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/V_dtC8p6Cqs/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8tpuYUiTTITmSgMfH8uZWMN",
        "position": 1,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "V_dtC8p6Cqs"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "V_dtC8p6Cqs",
        "videoPublishedAt": "2024-02-08T07:10:13Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "Z-TgJam1Ihv-YabG0M_UXRIvc7k",
      "id": "UExNelZuemJIYUI4dHB1WVVpVFRJVG1TZ01mSDh1WldNTi41QTY1Q0UxMTVCODczNThE",
      "snippet": {
        "publishedAt": "2024-01-18T06:34:57Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "Nudge Theory | Dr David Halpern",
        "description": "Karmayogi Talks presents David Halpern CBE, President, Behavioural Insights Team UK, in conversation with Adil Zainulbhai, Chairman of CBC, to discuss the NUDGE THEORY & other behavioural insights.\n\nThe Talks focused on generating and applying behavioural insights to inform policy and improve public services.",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/MW2d2WTLOTg/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/MW2d2WTLOTg/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/MW2d2WTLOTg/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/MW2d2WTLOTg/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/MW2d2WTLOTg/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8tpuYUiTTITmSgMfH8uZWMN",
        "position": 2,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "MW2d2WTLOTg"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "MW2d2WTLOTg",
        "videoPublishedAt": "2024-01-18T06:44:15Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "fvQB8y0VuS4bDqbczumArZRjkxA",
      "id": "UExNelZuemJIYUI4dHB1WVVpVFRJVG1TZ01mSDh1WldNTi5EQUE1NTFDRjcwMDg0NEMz",
      "snippet": {
        "publishedAt": "2023-12-07T11:48:51Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "David Eaves | Information Technology & Good Governance",
        "description": "This episode of Karmayogi Talks features Prof David Eaves, a renowned professor of digital government, in conversation with Abhishek Singh, CEO of Karmayogi Bharat; they discuss multiple facets of technology empowering good governance.",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/7B9Gdlo8ptk/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/7B9Gdlo8ptk/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/7B9Gdlo8ptk/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/7B9Gdlo8ptk/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/7B9Gdlo8ptk/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8tpuYUiTTITmSgMfH8uZWMN",
        "position": 3,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "7B9Gdlo8ptk"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "7B9Gdlo8ptk",
        "videoPublishedAt": "2023-12-07T11:51:56Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "G19U1ensPB3QVse5xnjY5RUW8oc",
      "id": "UExNelZuemJIYUI4dHB1WVVpVFRJVG1TZ01mSDh1WldNTi41Mzk2QTAxMTkzNDk4MDhF",
      "snippet": {
        "publishedAt": "2023-09-12T07:02:47Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "Opportunities in Artificial Intelligence | Karmayogi Talks",
        "description": "The latest episode of Karmayogi Talks features Brad Smith, Vice Chairperson and President of Microsoft, in conversation with Abhishek Singh, CEO of Karmayogi Bharat, as they delve into the fascinating world of \"Opportunities in Artificial Intelligence,\" and explore how AI is reshaping industries, opening new horizons, and creating a world of possibilities.",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/9U2zKJpM5NQ/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/9U2zKJpM5NQ/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/9U2zKJpM5NQ/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/9U2zKJpM5NQ/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/9U2zKJpM5NQ/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8tpuYUiTTITmSgMfH8uZWMN",
        "position": 4,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "9U2zKJpM5NQ"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "9U2zKJpM5NQ",
        "videoPublishedAt": "2023-09-12T07:08:23Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "-MJI6hbu_Yer5HSQ30yHWQ1zoQU",
      "id": "UExNelZuemJIYUI4dHB1WVVpVFRJVG1TZ01mSDh1WldNTi4zMDg5MkQ5MEVDMEM1NTg2",
      "snippet": {
        "publishedAt": "2023-08-29T10:38:22Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "The Last Mile | Amarjeet Sinha | Karmayogi Talks",
        "description": "Karmayogi Talks presents a discussion on \"The Last Mile\" by Amarjeet Sinha, delving into the crucial final steps of implementing policies and initiatives. Join us to explore how these last-mile efforts impact effective delivery and bring about real change in society.",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/sKGt8X1SFk8/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/sKGt8X1SFk8/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/sKGt8X1SFk8/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/sKGt8X1SFk8/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/sKGt8X1SFk8/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8tpuYUiTTITmSgMfH8uZWMN",
        "position": 5,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "sKGt8X1SFk8"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "sKGt8X1SFk8",
        "videoPublishedAt": "2023-08-29T10:47:46Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "9kI6FEP9XfQ1y6Q3Bs-3LPcdZRk",
      "id": "UExNelZuemJIYUI4dHB1WVVpVFRJVG1TZ01mSDh1WldNTi5EMEEwRUY5M0RDRTU3NDJC",
      "snippet": {
        "publishedAt": "2023-07-05T11:55:41Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "A Conversation With OpenAI's CEO Sam Altman (The Man Behind ChatGPT)",
        "description": "Through Digital India Dialogues and Karmayogi Talks, it's our endeavor to bring the best of the experts and practitioners from Industry, Government and Academja to share their insights into technology and how its impacting our lives. For this edition of Digital India Dialogues and Karmayogi Talks we bring to you Sam Altmam, CEO OpenAI in partnership with IIIT Delhi.",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/YKkE99Lwb1A/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/YKkE99Lwb1A/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/YKkE99Lwb1A/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/YKkE99Lwb1A/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/YKkE99Lwb1A/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8tpuYUiTTITmSgMfH8uZWMN",
        "position": 6,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "YKkE99Lwb1A"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "YKkE99Lwb1A",
        "videoPublishedAt": "2023-06-08T22:56:51Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "ly_bci1MkMTUeN7BxNLox5arPxQ",
      "id": "UExNelZuemJIYUI4dHB1WVVpVFRJVG1TZ01mSDh1WldNTi5DQUNERDQ2NkIzRUQxNTY1",
      "snippet": {
        "publishedAt": "2023-05-26T06:59:50Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "Excellence in Public Service through Digital Transformation | S. Ramadorai",
        "description": "#Watch the Episode of Karmayogi Talks featuring Shri S. Ramadorai (Chairperson, Karmayogi Bharat) as he takes viewers through key aspects such as Empowered Leadership, Spirit of Excellence, Digital Transformation, Mentorship, Learning Pathways and more.\n\n\n--------------------------------------------------\n\nKarmayogi Talks:\n\nEnvisioned under Mission Karmayogi as an interactive talk series with distinguished experts, and practitioners from the fields of public policy, capacity-building, governance, academia, civil society, and technology, Karmayogi Talks debuted on 2nd January, 2023.\n\nIt is envisaged to evolve as a forum of excellence providing leading figures from diverse fields a platform to share and exchange ideas, as well as engage with learners across Ministries and Departments, contributing towards their holistic professional, and personal development.",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/PcMtYoLiVT4/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/PcMtYoLiVT4/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/PcMtYoLiVT4/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/PcMtYoLiVT4/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/PcMtYoLiVT4/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8tpuYUiTTITmSgMfH8uZWMN",
        "position": 7,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "PcMtYoLiVT4"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "PcMtYoLiVT4",
        "videoPublishedAt": "2023-05-26T08:30:08Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "QeTgYAaBJ9FvqRK5LFfnAskeOh0",
      "id": "UExNelZuemJIYUI4dHB1WVVpVFRJVG1TZ01mSDh1WldNTi41MzJCQjBCNDIyRkJDN0VD",
      "snippet": {
        "publishedAt": "2023-04-11T12:00:09Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "Future of Work | Jamie McAuliffe",
        "description": "Karmayogi Bharat presents a special episode of #KarmayogiTalks on #FutureOfWork, featuring Jamie McAuliffe (Founding Director, Global Opportunity Youth Network, The Aspen Institute), in conversation with Praveen Pardeshi (Member, Capacity Building Commission) & Abhishek Singh (CEO, Karmayogi Bharat).\n\n--------------------------------------------------\n\nKarmayogi Talks:\n\nEnvisioned under Mission Karmayogi as an interactive talk series with distinguished experts, and practitioners from the fields of public policy, capacity-building, governance, academia, civil society, and technology, Karmayogi Talks debuted on 2nd January, 2023.\n\nIt is envisaged to evolve as a forum of excellence providing leading figures from diverse fields a platform to share and exchange ideas, as well as engage with learners across Ministries and Departments, contributing towards their holistic professional, and personal development.",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/g5s5csQSOMw/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/g5s5csQSOMw/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/g5s5csQSOMw/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/g5s5csQSOMw/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/g5s5csQSOMw/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8tpuYUiTTITmSgMfH8uZWMN",
        "position": 8,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "g5s5csQSOMw"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "g5s5csQSOMw",
        "videoPublishedAt": "2023-04-12T10:30:10Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "lwT2zssYxh9ryOUQNdCuXvhycyQ",
      "id": "UExNelZuemJIYUI4dHB1WVVpVFRJVG1TZ01mSDh1WldNTi4xMkVGQjNCMUM1N0RFNEUx",
      "snippet": {
        "publishedAt": "2023-04-03T10:48:00Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "Doing Transformational Projects In Government | Nandan Nilekani",
        "description": "Karmayogi Talks is organised in partnership with IIM Bangalore, and Wadhwani Foundation. Our speaker for the session is Shri Nandan Nilekani, Co-founder & Chairman of Infosys Technologies Limited; Founding Chairman of the Unique Identification Authority of India (UIDAI); Co-Chair of the G20 Task Force on Digital Public Infrastructure.\n\nThis session will focus on the subject of the implementation of digital transformation projects in the government. All public officials will benefit from this topic of discussion given its relevance and applicability across diverse fields.",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/diuK-Kn7VnM/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/diuK-Kn7VnM/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/diuK-Kn7VnM/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/diuK-Kn7VnM/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/diuK-Kn7VnM/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8tpuYUiTTITmSgMfH8uZWMN",
        "position": 9,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "diuK-Kn7VnM"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "diuK-Kn7VnM",
        "videoPublishedAt": "2023-03-30T17:42:48Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "iMh2zO61AETVYUPTiU3pfRzZl2E",
      "id": "UExNelZuemJIYUI4dHB1WVVpVFRJVG1TZ01mSDh1WldNTi4wOTA3OTZBNzVEMTUzOTMy",
      "snippet": {
        "publishedAt": "2023-03-17T05:38:37Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "DigitAll: Innovation & Tech For Gender Equality | Radha Chauhan & Debjani Ghosh",
        "description": "Karmayogi Bharat presents the #WomensDay special edition of #KarmayogiTalks, featuring Ms S. Radha Chauhan (Secretary, Department of Personnel &Training) & Ms Debjani Ghosh (President, NASSCOM), in conversation with Mr Abhishek Singh (CEO, Karmayogi Bharat) on- ‘DigitAll: Innovation & Technology for Gender Equality’.\n\n--------------------------------------------------\n\nKarmayogi Talks:\n\nEnvisioned under Mission Karmayogi as an interactive talk series with distinguished experts, and practitioners from the fields of public policy, capacity-building, governance, academia, civil society, and technology, Karmayogi Talks debuted on 2nd January, 2023.\n\nIt is envisaged to evolve as a forum of excellence providing leading figures from diverse fields a platform to share and exchange ideas, as well as engage with learners across Ministries and Departments, contributing towards their holistic professional, and personal development.\n\n#MissionKarmayogi #WomensDay #KarmayogiBharat",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/1HB7cYZIKEw/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/1HB7cYZIKEw/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/1HB7cYZIKEw/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/1HB7cYZIKEw/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/1HB7cYZIKEw/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8tpuYUiTTITmSgMfH8uZWMN",
        "position": 10,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "1HB7cYZIKEw"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "1HB7cYZIKEw",
        "videoPublishedAt": "2023-03-17T05:38:10Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "4SuIF-bTGDea_6yRMRHYLpsX0h8",
      "id": "UExNelZuemJIYUI4dHB1WVVpVFRJVG1TZ01mSDh1WldNTi41MjE1MkI0OTQ2QzJGNzNG",
      "snippet": {
        "publishedAt": "2023-02-27T05:12:43Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "Design Change | Patrick Whitney & André Nogueira",
        "description": "Karmayogi Bharat presents #KarmayogiTalks featuring Prof Patrick Whitney & Dr Andre Nogueira, from Harvard T.H Chan School of Public Health, as speakers on- ‘Design Change’. \n\nModerated by Shri Abhishek Singh (CEO, Karmayogi Bharat), Prof Whitney & Dr Nogueira will be in conversation with Shri Praveen Pardeshi (Member, Capacity Building Commission).\n\n-------------------------------------------------\n\nKarmayogi Talks:\n\nEnvisioned under Mission Karmayogi as an interactive talk series with distinguished experts, and practitioners from the fields of public policy, capacity-building, governance, academia, civil society, and technology, Karmayogi Talks debuted on 2nd January, 2023.\n\nIt is envisaged to evolve as a forum of excellence providing leading figures from diverse fields a platform to share and exchange ideas, as well as engage with learners across Ministries and Departments, contributing towards their holistic professional, and personal development.",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/fOPdBAEFT-M/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/fOPdBAEFT-M/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/fOPdBAEFT-M/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/fOPdBAEFT-M/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/fOPdBAEFT-M/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8tpuYUiTTITmSgMfH8uZWMN",
        "position": 11,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "fOPdBAEFT-M"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "fOPdBAEFT-M",
        "videoPublishedAt": "2023-02-24T10:30:08Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "p7kIUjNTeb6YSiHGb0WE1sXY2Do",
      "id": "UExNelZuemJIYUI4dHB1WVVpVFRJVG1TZ01mSDh1WldNTi4wMTcyMDhGQUE4NTIzM0Y5",
      "snippet": {
        "publishedAt": "2023-01-31T19:37:13Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "Innovation in Agriculture & Water Resources Using India's Digital Infra | Michael Kremer",
        "description": "Karmayogi Bharat presents the 3rd session of #KarmayogiTalks, featuring noted economist, and Nobel Laureate Prof Michael Kremer as the speaker on- ‘Driving Innovation in Agriculture, and Water Resources by Leveraging India’s Digital Infrastructure’. \n\nModerated by Shri Abhishek Singh, CEO, Karmayogi Bharat, Prof Kremer will be in conversation with Shri Manoj Ahuja, Secretary, Ministry of Agriculture and Farmers' Welfare, and Shri Praveen Pardeshi, Member, Capacity Building Commission.\n\n-------------------------------------------------\n\nKarmayogi Talks:\n\nEnvisioned under Mission Karmayogi as an interactive talk series with distinguished experts, and practitioners from the fields of public policy, capacity-building, governance, academia, civil society, and technology, Karmayogi Talks debuted on 2nd January, 2023.\n\nIt is envisaged to evolve as a forum of excellence providing leading figures from diverse fields a platform to share and exchange ideas, as well as engage with learners across Ministries and Departments, contributing towards their holistic professional, and personal development.",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/-2NooUjxnqo/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/-2NooUjxnqo/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/-2NooUjxnqo/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/-2NooUjxnqo/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/-2NooUjxnqo/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8tpuYUiTTITmSgMfH8uZWMN",
        "position": 12,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "-2NooUjxnqo"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "-2NooUjxnqo",
        "videoPublishedAt": "2023-02-01T02:57:04Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "sN5iPxNscR8SYfEt_bmqmJsZZPI",
      "id": "UExNelZuemJIYUI4dHB1WVVpVFRJVG1TZ01mSDh1WldNTi4yODlGNEE0NkRGMEEzMEQy",
      "snippet": {
        "publishedAt": "2023-01-31T19:37:02Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "Transition from 'Karamchari' to a ‘Karmayogi’ | Dr R Balasubramaniam",
        "description": "#Watch the introductory episode of Karmayogi Talks featuring Dr R. Balasubramaniam (Member, Capacity Building Commission) as the speaker on the subject, ‘Transition from Karmachari to Karmayogi’. Moderated by Shri Abhishek Singh (CEO, Karmayogi Bharat), the session seeks to familiarize the participants on the key facets of the National Program for Civil Services Capacity Building (Mission Karmayogi), transition from rule-based to role-based governance, and the goal of nurturing a smart, skilled, responsive, citizen-friendly government workforce. \n\n--------------------------------------\n\nKarmayogi Talks:\n\nEnvisioned under Mission Karmayogi as an interactive talk series with distinguished experts, and practitioners from the fields of public policy, capacity-building, governance, academia, civil society, and technology, Karmayogi Talks debuted on 2nd January, 2023.\n\nIt is envisaged to evolve as a forum of excellence providing leading figures from diverse fields a platform to share and exchange ideas, as well as engage with learners across Ministries and Departments, contributing towards their holistic professional, and personal development.",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/771Sug61_jU/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/771Sug61_jU/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/771Sug61_jU/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/771Sug61_jU/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/771Sug61_jU/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8tpuYUiTTITmSgMfH8uZWMN",
        "position": 13,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "771Sug61_jU"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "771Sug61_jU",
        "videoPublishedAt": "2023-01-02T23:50:57Z"
      }
    },
    {
      "kind": "youtube#playlistItem",
      "etag": "xbQ6ndEEqnWteEzaV5r_WrfsgSQ",
      "id": "UExNelZuemJIYUI4dHB1WVVpVFRJVG1TZ01mSDh1WldNTi41NkI0NEY2RDEwNTU3Q0M2",
      "snippet": {
        "publishedAt": "2023-01-31T19:36:50Z",
        "channelId": "UCPO2faT8YEi6Q_2IY5kf2Dg",
        "title": "New & Emerging Technologies in Governance | R Chandrashekhar",
        "description": "Second session of Karmayogi Talks series on New and Emerging Technologies in Governance, being organized in partnership with Wadhwani Institute of Technology Policy and IIM Bangalore. Our speaker for the session is Shri R Chandrashekhar, former Secretary MEITY and Telecom. \n\nThis session aims to highlight the various innovative technologies and the importance and challenges of using them in the government. All government officials will benefit from this topic of discussion given its relevance across diverse fields.  \n\nTeam Karmayogi Bharat",
        "thumbnails": {
          "default": {
            "url": "https://i.ytimg.com/vi/hY08vxxIhLo/default.jpg",
            "width": 120,
            "height": 90
          },
          "medium": {
            "url": "https://i.ytimg.com/vi/hY08vxxIhLo/mqdefault.jpg",
            "width": 320,
            "height": 180
          },
          "high": {
            "url": "https://i.ytimg.com/vi/hY08vxxIhLo/hqdefault.jpg",
            "width": 480,
            "height": 360
          },
          "standard": {
            "url": "https://i.ytimg.com/vi/hY08vxxIhLo/sddefault.jpg",
            "width": 640,
            "height": 480
          },
          "maxres": {
            "url": "https://i.ytimg.com/vi/hY08vxxIhLo/maxresdefault.jpg",
            "width": 1280,
            "height": 720
          }
        },
        "channelTitle": "Karmayogi Bharat",
        "playlistId": "PLMzVnzbHaB8tpuYUiTTITmSgMfH8uZWMN",
        "position": 14,
        "resourceId": {
          "kind": "youtube#video",
          "videoId": "hY08vxxIhLo"
        },
        "videoOwnerChannelTitle": "Karmayogi Bharat",
        "videoOwnerChannelId": "UCPO2faT8YEi6Q_2IY5kf2Dg"
      },
      "contentDetails": {
        "videoId": "hY08vxxIhLo",
        "videoPublishedAt": "2023-01-12T18:13:58Z"
      }
    }
  ]
  courseListLoaded:boolean = false
  youtubeData:any

  constructor(
    private httpClient: HttpClient,
    private sharedService: SharedService
    ) { }

  ngOnInit() {
    if (this.slidesList.length !== 0) {
      this.data = this.slidesList.find(ele => { if (ele) return ele.videoCategory === this.currentTab });
      this.getYoutubeData(this.currentTab)
    }
  }

  ontabChange(tab: string) {
    this.currentTab = tab;
    this.data = this.slidesList.find(ele => { if (ele) return ele.videoCategory === tab });
    this.getDataList(this.currentTab);
    
  }

  getYoutubeData(type:string) {
    let url = `${this.sharedService.baseUrl}${this.landingPageurl}`;
    this.courseListLoaded = false
    this.data.dataList = [];
    this.httpClient.get(url).subscribe((data: any) => {
      
      this.youtubeData = data
      this.courseListLoaded = true
      if (data) {
        this.getDataList(type)
      }
      

    },(error:any)=>{
      this.showVideoGallery = false;
      this.courseListLoaded = true
    })
  }

  getDataList(type:string) {
    this.data.dataList = [];
    
    if (this.youtubeData) {
      switch(type) {
        case 'course_intro':
          this.data.dataList = this.youtubeData['COURSE_PREVIEW']['items'].reduce((marray, item)=>{
            if(item.snippet && item.snippet.title) {
              if(item.snippet.title.toLowerCase() !== 'deleted video' && item.snippet.title.toLowerCase() !== 'private video') {
                marray.push(item)
              }              
            }   
            return marray;         
          },[])
          break;
        case 'karmayogi_talks':
          this.data.dataList = this.youtubeData['KARMAYOGI_TALKS']['items'].reduce((marray, item)=>{
            if(item.snippet && item.snippet.title) {
              if(item.snippet.title.toLowerCase() !== 'deleted video' && item.snippet.title.toLowerCase() !== 'private video') {
                marray.push(item)
              }              
            }   
            return marray;         
          },[])
          break;
      }
    }
  }

}