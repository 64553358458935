import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-testimonial-slider',
  templateUrl: './testimonial-slider.component.html',
  styleUrls: ['./testimonial-slider.component.scss']
})
export class TestimonialSliderComponent implements OnInit, OnChanges {

  @Input() testimonial: any;
  @Input() currentIndex: number;
  @Input() activeIndex: number | undefined
  card:any
  playVideo = false;
  youtubeLink: string | SafeUrl;

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && this.activeIndex !== this.currentIndex) {
      this.playVideo = false
    } 
  }

  ngOnInit() {
    this.initializeIframeVideo()
  }

  initializeIframeVideo() {
    this.youtubeLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.testimonial.artifactUrl + '&autoplay=1'); //NOSONAR
  }

}
