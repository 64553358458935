import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-videoconference-maintain',
  templateUrl: './videoconference-maintain.component.html',
  styleUrls: ['./videoconference-maintain.component.scss']
})
export class VideoconferenceMaintainComponent {
  @Input() videoConf: any;

  joinVideoConference(joinLink: string) {
    window.open(joinLink, '_blank')
  }
}
