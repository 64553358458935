import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoconferenceComponent } from '../../components/videoconference/videoconference.component';
import { MobileappDownloadComponent } from '../../components/mobileapp-download/mobileapp-download.component';
import { AppDownloadComponent } from '../../components/app-download/app-download.component';
import { FooterNavComponent } from '../../components/footer-nav/footer-nav.component';
import { TranslateModule } from '@ngx-translate/core';
import { SkeletonLoaderModule } from '../../components/skeleton-loader/skeleton-loader.module';
import { SociallinksComponent } from '../../components/sociallinks/sociallinks.component';
import { FooterProviderComponent } from '../../components/footer-provider/footer-provider.component';
import { IframeExternalComponent } from '../../components/iframe-external/iframe-external.component';



@NgModule({
  declarations: [
    VideoconferenceComponent,
    MobileappDownloadComponent,
    AppDownloadComponent,
    FooterNavComponent,
    SociallinksComponent,
    FooterProviderComponent,
    IframeExternalComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SkeletonLoaderModule,
  ],
  exports: [
    VideoconferenceComponent,
    MobileappDownloadComponent,
    AppDownloadComponent,
    FooterNavComponent,
    SociallinksComponent,
    FooterProviderComponent,
    IframeExternalComponent
  ]
})
export class CommoncomponentsModule { }
