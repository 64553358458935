import { AfterViewInit, Component, OnInit } from '@angular/core';
import { InitService } from '../../services/init.service';
import { timer } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SOCIAL_LINKS } from '../../constant/app.constant';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-social-hub',
  templateUrl: './social-hub.component.html',
  styleUrls: ['./social-hub.component.scss']
})
export class SocialHubComponent implements OnInit, AfterViewInit {
  linkedInLink: string = 'https://www.linkedin.com/embed/feed/update/urn:li:share:7163766722796179456';
  twitterEmbedCode: SafeHtml;
  socialLinks = SOCIAL_LINKS;
  socialHub = {
    header: {
      headerText: ``,
      type: `social-hub`
    },
    dataList: [
      {
        icon: './assets/social-hub/Linkedin.svg',
        type: 'linkedin',
        name: 'Linkedin'
      },
      {
        icon: './assets/social-hub/Subtract.svg',
        type: 'twitter',
        name: 'X (Twitter)'
      }
    ]
  }

  constructor(public sanitizer: DomSanitizer, public sharedService: SharedService) { }
  ngOnInit() {
    this.twitterEmbedCode = this.sanitizer.bypassSecurityTrustHtml(`
    <blockquote class="twitter-tweet">
      <p lang="en" dir="ltr">If the plan doesn't work, change the plan but never the goal. <a href="https://t.co/4jJRGkC8l1">pic.twitter.com/4jJRGkC8l1</a></p>
      &mdash; Nazir Hussain (@nazirhssn0) <a href="https://twitter.com/nazirhssn0/status/1760314672149082386?ref_src=twsrc%5Etfw">February 21, 2024</a>
    </blockquote>
    <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
  `);

  }

  ngAfterViewInit(): void {
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.charset = 'utf-8';
    document.getElementById('body').appendChild(script);
  }

  openLink(type: string) {
    const link = this.socialLinks.find(ele => ele.name === type);
   if (link) {
    window.open(link.href_url, '_blank');
   }
  }
}
