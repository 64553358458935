import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-footer-provider',
  templateUrl: './footer-provider.component.html',
  styleUrls: ['./footer-provider.component.scss']
})
export class FooterProviderComponent implements AfterViewInit {

  @Input() navFooterDetails: any = {}
  @Input() footerProvider: any = []

  @ViewChild('footerProviderEle', {static: true}) footerProviderEle: ElementRef;

  scrollInterval: any;
  scrollSpeed: number = 5000; // 5 seconds

  ngAfterViewInit() {
    this.startScrolling();
  }

  startScrolling() {
    this.scrollInterval = setInterval(() => {
      this.scrollRight();
    }, this.scrollSpeed);
  }

  scrollRight() {
    if (this.footerProviderEle && this.footerProviderEle.nativeElement) {
      const container = this.footerProviderEle.nativeElement;
      container.scrollLeft += 200;

      if (container.scrollLeft >= (container.scrollWidth - container.offsetWidth)) {
        container.scrollTo({ left: 0, behavior: 'smooth' });
      }
    }
  }

  stopScrolling() {
    clearInterval(this.scrollInterval);
  }

  imageloaded(icon) {
    icon['imageLoaded'] = true
  }

}
