import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-hall-of-fame',
  templateUrl: './hall-of-fame.component.html',
  styleUrls: ['./hall-of-fame.component.scss']
})

export class HallOfFameComponent implements OnInit {
  searchText: string = ''
  fetching = true
  hallofFameData!: any
  kpToolTipText = `The Wall of Fame showcases the Top 5 MDOs based on their cumulative scores earned in the previous month. It will also feature tabs that define the user base and be updated on the first day of every month.`
  sameRankTooltipMsg = `This icon denotes MDOs achieving equal Karma Points. Different ranks may occur because the MDO that earned those points first in the month ranks higher.`
  modoListByChunks: any = []
  chunckData: any
  activeTab = 'xs'
  constructor(
    private sharedSvc: SharedService,
  ) { }

  ngOnInit() {

    let slabData = {
      'xs':{
        'mdoList': []
      },
      's':{
        'mdoList': []
      },
      'm':{
        'mdoList': []
      },
      'l':{
        'mdoList': []
      },
      'xl':{
        'mdoList': []
      },
    }
    this.modoListByChunks = []
      this.modoListByChunks = [
        {
          id:'xl',
          name:'hallOfFame.tabHeading_2'
        },
        {
          id:'l',
          name:'hallOfFame.tabHeading_3'
        },
        {
          id:'m',
          name:'hallOfFame.tabHeading_4'
        },
        {
          id:'s',
          name:'hallOfFame.tabHeading_5'
        },
        {
          id:'xs',
          name:'hallOfFame.tabHeading_6'
        }      
      ]
    this.getMdoListData(slabData)
  }

  getMdoListData(slabData: any){
    this.sharedSvc.getHallOfFame().subscribe(response => {
      this.hallofFameData = response
     
      // sort by rank
      this.hallofFameData.mdoList = this.hallofFameData.mdoList.sort((a,b) => a.rank - b.rank);
      
      this.hallofFameData.mdoList.forEach((mdo) => {
        if(mdo.size === 'XS') {
          slabData['xs']['mdoList'].push(mdo)
        } else if(mdo.size === 'S') {
          slabData['s']['mdoList'].push(mdo)
        } else if(mdo.size === 'M') {
          slabData['m']['mdoList'].push(mdo)
        } else if(mdo.size === 'L') {
          slabData['l']['mdoList'].push(mdo)
        } else {
          slabData['xl']['mdoList'].push(mdo)
        }
      })
      this.chunckData = slabData
      this.fetching = false
      this.changeTab(this.modoListByChunks[0])
    }, (err) => {
      this.fetching = false
      this.chunckData = slabData
      console.log('Error in fetching hall of fame')
    })
  }

  getMDOByRank(rank) {
    if(this.hallofFameData && this.hallofFameData.mdoList) {
      const mdo =  this.chunckData[this.activeTab].mdoList.find(mod => mod.rank === rank);
      return mdo
    }
  }

  changeTab(data: any){
    this.activeTab = data.id
    this.chunckData[this.activeTab].mdoList.forEach((mdo, i) => {
      if(this.chunckData[this.activeTab].mdoList[i-1] && (this.chunckData[this.activeTab].mdoList[i-1].total_kp === mdo.total_kp)){
        if(this.chunckData[this.activeTab].mdoList[i-1]){
          this.chunckData[this.activeTab].mdoList[i-1].showClock = true
        }
        mdo.showClock = true
      }
  });
    
  }

}
